/** @jsx jsx */

import {Component} from 'react'

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Text from "../../components/Text";
import {Wrap} from "../../components/grid/Container";
import {SingleCenter} from "../../components/grid/SingleCenter";
import {paddingStyle} from "../../components/grid/Container";

import SVG from "react-inlinesvg";

const Base = styled.div`
  ${tw`py-10`};
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-color: #FEFCF2;
  width: 100%;
  text-align: center;
`;

const ElementWrap = styled.div`
    ${tw`w-full`}
`

const ElementTrigger = styled.div`
    ${tw`font-display text-xl text-left text-brand py-4 border-t-8 cursor-pointer flex flex-row align-top relative`}
`

const ElementTitle = styled.div`
    flex-grow: 1;
`

const ElementExpansion = styled.div`
    ${tw`hidden text-left`}
`

const ElementOpen = css(tw`block`)
const ElementWrapLast = css(tw`border-b-8 border-brand`)
const SVGWrap = styled.span`
    position: absolute;
    right: 5px;
    padding: 10px;
    transform: rotate(90deg);
    transition: transform 0.25s ease;
    path {
        fill: #2f52a0;
    }
`

export class AccordeonElement extends Component {

    state = {
        isOpen: false
    }


    render() {
        const {entry, isLast} = this.props
        const {isOpen} = this.state

        return (
            <ElementWrap css={isLast ? ElementWrapLast : {}}>
                <ElementTrigger onClick={ () => this.setState({isOpen: !isOpen})}>
                    <ElementTitle>
                        {entry.headline}
                    </ElementTitle>
                    <SVGWrap css={isOpen ? {transform: 'rotate(-90deg)'} : {}}>
                        <SVG src={'img/chevron-right.svg'} />
                    </SVGWrap>
                </ElementTrigger>
                <ElementExpansion css={isOpen ? ElementOpen : {}}>
                    <Text text={entry.body} />
                </ElementExpansion>
            </ElementWrap>
        )
    }
}

class Accordeon extends Component {
    render() {
        const {elements} = this.props
        return (
            <Base className="breaker">
                <Wrap css={paddingStyle}>
                    <SingleCenter wide padded={false}>
                        {
                            elements.map((el, i) => <AccordeonElement key={i} entry={el} isLast={elements.length-1 === i} />)
                        }
                    </SingleCenter>
                </Wrap>
            </Base>
        )
    }
}


export default Accordeon
