import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../Title";
import Container from "../../components/grid/Container";
import {Column57Container, ColumnTighter, ColumnWide} from "../../components/grid/Column57";
import Text from "../../components/Text";
import ContactCell from "../../components/ContactCell"

const Image = styled.img`
    ${tw`w-full`};
`

class TextContact extends Component {

    static defaultProps = {
        titleTag: 'h2',
        inlineTitle: false,
        reverseOrder: false,
    }

    state = {
        menuOpen: false
    }

    renderContactCells() {
        const {contactWidgets} = this.props
        if (!contactWidgets || !contactWidgets.length) return null

        return contactWidgets.map((widget,i) => {
            switch (widget) {
                case 'phone':
                    return (
                        <ContactCell href="tel:04231982720" key={widget+i} iconPath={'/img/icon-phone.svg'}>
                            04231 98 272 - 0<br />
                            Mo-Do. 08 - 17h<br />
                            Fr. 08 - 13h
                        </ContactCell>
                    )
                case 'address':
                    return (
                        <ContactCell key={widget+i} iconPath={'/img/icon-location.svg'}>
                            Große Straße 2<br />
                            27283 Verden
                        </ContactCell>
                    )
                case 'mail':
                    return (
                        <ContactCell href="mailto:info@steuern-verden.de" key={widget+i} iconPath={'/img/icon-mail.svg'}>
                            info(at)steuern-verden.de<br />
                            24h
                        </ContactCell>
                    )
                default:
            }
            return null
        })

    }

    render() {
        const {headline, titleTag, body, afterBody, image, rowReverse} = this.props

        return (
            <div className="textcontact">
                {headline ? (
                    <Container padded={false} paddedTop={true}>
                        <Title titleTag={titleTag} title={headline} mobileCenter={true} bordered={true} />
                    </Container>
                ) : null}
                <Container padded={false} paddedBottom >
                    <Column57Container reverseOrder={rowReverse}>
                        <ColumnWide>
                            { body ? (
                                <Text>
                                    <span dangerouslySetInnerHTML={{__html: body}} />
                                </Text>
                            ) : null}
                            {image ? (
                                <Image src={image} alt=""/>
                            ) : null}
                            {afterBody}
                        </ColumnWide>
                        <ColumnTighter>
                            { this.renderContactCells() }
                        </ColumnTighter>
                    </Column57Container>
                </Container>
            </div>
        )
    }
}

export default TextContact
