/** @jsx jsx */

import React from 'react'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {SingleCenter} from "../components/grid/SingleCenter";
import {css, jsx} from "@emotion/core";

const Wrap = styled.nav`
	${tw`font-display py-10`};
	
`

export default class Pagination extends React.Component {
    render() {
        const {url, currentPage, totalPages} = this.props.pagination

        console.log(this.props.pagination)

        return (
            <Wrap className={'menu'}>
                <SingleCenter wide padded={false}>
                    {
                        [...Array(totalPages)].map((x, idx) => {
                                return (
                                    <PaginationLink
                                        key={idx}
                                        url={url}
                                        currentPage={currentPage}
                                        page={idx} />
                                )
                            }
                        )
                    }
                </SingleCenter>
            </Wrap>
        )
    }
}

const PaginationLinkBase = styled.a`
    ${tw`font-display text-xl text-brand px-2`}
`
const activePage = css(tw`text-grey cursor-text`)


const PaginationLink = ({url, page, currentPage}) => {
    const isActive = page === currentPage-1

    return (
        <PaginationLinkBase
            css={isActive ? activePage : {}}
            href={`${url}?page=${page+1}`}>
            {page+1}
        </PaginationLinkBase>
    )
}
