/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {getStyles} from "../utils";

const Element = styled.a`
  ${tw`bg-white border-2 text-brand font-display py-3 px-10 rounded`};
`

const inverted = css(tw`border-white bg-transparent text-white antialiased`)
const styleables = { inverted }

export const Link = (props) => {
    const {title, href} = props
    const styles = getStyles(props, styleables)

    return (
        <Element href={href} css={styles}>
            {title}
        </Element>
    )
}

export default Link