/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {getStyles} from "../utils";

const Element = styled.button`
  ${tw`bg-brand text-white font-display py-3 px-10 rounded`};
`

const inverted = css(tw`bg-white antialiased text-green`)
const styleables = { inverted }

export const Button = (props) => {
    const {title, onClick} = props
    const styles = getStyles(props, styleables)

    return (
        <Element css={styles} onclick={onClick}>
            {title}
        </Element>
    )
}

export default Button