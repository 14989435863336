import React from 'react';

export default class Csrf extends React.Component {
	render() {
		const name = window.csrfTokenName
		const value = window.csrfTokenValue
		return (
			<input type="hidden" name={name} value={value} />
		)
	}
}
