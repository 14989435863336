import React, {Component} from 'react'

import Title from "../Title";
import Container from "../../components/grid/Container";
import {Column57Container, ColumnWide} from "../../components/grid/Column57";
import Text from "../../components/Text";

class TextBlock extends Component {

    static defaultProps = {
        titleTag: 'h2'
    }

    render() {
        const {headline, body, titleTag, rowReverse} = this.props

        return (
            <div className="textblock">
                <Container padded={false} paddedBottom={true}>
                    <Column57Container reverseOrder={rowReverse}>
                        <ColumnWide>
                            {headline && (
                                <Title titleTag={titleTag} title={headline} mobileCenter={true} bordered={true} />
                            )}
                            <Text text={body} marginless />
                        </ColumnWide>
                    </Column57Container>
                </Container>
            </div>
        )
    }
}

export default TextBlock
