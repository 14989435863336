/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import SVG from 'react-inlinesvg';

const Element = styled.a`
    ${tw`bg-off-white block flex flex-col p-8 mb-8 items-center font-display text-brand w-full`}
`

const Content = styled.div`
    ${tw`pt-4 text-center`}
`

export const ContactCell = (props) => {
    const { iconPath, children, href } = props

    return (
      <Element href={href || 'javascript:;'}>
        { iconPath ? (
          <SVG src={iconPath} />
        ) : null }
        { children ? (
          <Content>
            {children}
          </Content>
        ) : null}
      </Element>
    )
}

export default ContactCell
