/** @jsx jsx */

import React, {Fragment} from 'react'
import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import ContentMapper from "../../helpers/ContentMapper";

const Base = styled.article`
`

export default class Default extends React.Component {

    renderContent() {
        const { entry, children } = this.props
        if (!entry || !entry.contentBlocks) {
            return  (
                <Fragment>
                    {children}
                </Fragment>
            )
        }
        const { contentBlocks } = entry
        return ContentMapper.mapContentBlocks(contentBlocks)
    }

    render() {
        return (
            <Base>
                {this.renderContent()}
            </Base>
        )
    }
}