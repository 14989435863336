/** @jsx jsx */

import React, { Fragment } from 'react'
import { jsx } from '@emotion/core'
import ButtonLink from '../../ButtonLink';
import tw from 'tailwind.macro'
import Csrf from '../../Csrf';
import styled from "@emotion/styled/macro"
import { Loader } from '../../Loader';

const FunnelBox = styled.div`
  ${ tw` border border-brand rounded flex flex-col md:mx-8 px-8 pb-4 pt-8 my-8` };
`

export default class JobFunnel extends React.Component {

	state = {
		isFormValid: false,
		form: {
			name: '',
			phone: '',
			email: ''
		},
		isLoading: false,
		isSubmitted: false,
		showFunnelOpen: false,
	}

	handleSubmit = async  (e) => {
		e.preventDefault()
		this.setState({isLoading: true})
		const {title} = this.props.job

		const payload = {
			...this.state.form,
			title
		}

		console.log(payload)


		const rawResponse = await fetch('/api/job/submit', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': window.csrfTokenValue,
				'X-Hash': 'x9001'
			},
			body: JSON.stringify(payload)
		});

		const content = await rawResponse.json();

		this.setState({isLoading: false, isSubmitted: true})
	}

	handleChange = (event) => {
		const form = JSON.parse(JSON.stringify(this.state.form))
		form[event.target.id] = event.target.value
		this.setState({form});
	}

	renderForm() {
		const canSubmit = !Object.values(this.state.form).filter(v => !v.length).length

		if (this.state.isLoading) {
			return (
				<FunnelBox>
					<Loader centered />
				</FunnelBox>
			)
		}

		if (this.state.isSubmitted) {
			return (
				<FunnelBox>
					<div css={tw`flex justify-center text-center flex-col leading-tight pb-8`}>
						<h2 className={'h3'}>Vielen Dank!</h2>
						<p>Wir werden uns zeitnah bei Ihnen melden!</p>
					</div>
				</FunnelBox>
			)
		}
		return (
			<form action={'#'}  onSubmit={this.handleSubmit}>
				<FunnelBox>
					<h2 className={'h3'}>Ihre Kontaktdaten:</h2>
					<Csrf />

					<div css={tw`mt-8 mb-4`}>
						<input
							type="text"
							id="name"
							css={tw`border-b border-brand text-gray-900 text-md  block w-full p-2`}
							value={this.state.form.name} onChange={this.handleChange}
							placeholder="Name*"
							required
						/>
					</div
					>
					<div css={tw`mt-2 mb-4`}>
						<input
							type="email"
							id="email"
							css={tw`border-b border-brand text-gray-900 text-md  block w-full p-2`}
							placeholder="Email*"
							required
							value={this.state.form.email} onChange={this.handleChange}
						/>
					</div>

					<div css={tw`mt-2 mb-4`}>
						<input
							type="tel"
							id="phone"
							css={tw`border-b border-brand text-gray-900 text-md  block w-full p-2`}
							placeholder="Telefon"
							value={this.state.form.phone} onChange={this.handleChange}
						/>
					</div>

					<div css={tw`mt-2 mb-6 flex justify-end`}>
						<button
							disabled={!canSubmit}
							css={[
								tw`bg-brand text-white font-display py-3 px-10 rounded hover:bg-brand-dark`,
								canSubmit ? tw`bg-brand cursor-pointer` : tw`bg-grey hover:bg-grey cursor-none`,

							]}
							type="submit"
						>
							Absenden
						</button>
					</div>
				</FunnelBox>

			</form>
		)
	}


	render() {
		return (
			<div css={tw`-mt-8 block`}>
				<div className={'text'}>
					<h2 className={ 'h3' }>Interesse geweckt? Jetzt formlos bewerben!</h2>
					<p>
						Sie haben Interesse? Dann bewerben Sie sich gern bei uns.
						Wir freuen uns Sie kennenzulernen.
					</p>
					<p>
						Ein Erstgespräch ist formlos per Telefon oder E-Mail möglich. Gern rufen wir Sie auch bei Interesse an. Hinterlassen Sie Ihre Kontaktdaten im kurzen Bewerbungsformular.
					</p>
					{ !this.state.showFunnelOpen ? (
						<div css={tw`flex justify-center my-8`}>
							<ButtonLink isButton title={ 'Jetzt formlos bewerben!' } onClick={(e) => this.setState({ showFunnelOpen: true})}/>
						</div>
					) : this.renderForm()}

				</div>
			</div>
		)
	}
}
