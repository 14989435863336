/** @jsx jsx */

import React, {Fragment} from 'react'
import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import ContentMapper from "../../helpers/ContentMapper";
import HeroSmall from "../../components/blocks/HeroSmall";
import TextBlock from "../../components/blocks/TextBlock";
import Breaker from "../../components/blocks/Breaker";

const Base = styled.article`
`


export default class Steuertip extends React.Component {

    renderContent() {
        const { entry, children } = this.props

        return  (
            <Fragment>
                <TextBlock body={entry.body}></TextBlock>
                {children}
                { entry.breaker && (
                    <Breaker
                    {...entry.breaker.entry}
                    titleTag={'h2'}
                    white={true} />
                )}
            </Fragment>
        )
    }

    renderHeadline() {
        const { entry } = this.props

        return (
            <HeroSmall headline={entry.title} backgroundImage={entry.heroBackgroundImage} />
        )
    }

    render() {
        return (
            <Base>
                {this.renderHeadline()}
                {this.renderContent()}
            </Base>
        )
    }

}