/** @jsx jsx */

import React, { Fragment } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled/macro'
import HeroSmall from '../../components/blocks/HeroSmall';
import Breaker from '../../components/blocks/Breaker';
import TextContact from '../blocks/TextContact';
import JobFunnel from './job/JobFunnel';

const Base = styled.article``

export default class Job extends React.Component {

    renderContent() {
        const { entry, children } = this.props

        const contactWidgets = ['mail', 'phone', 'address']
        const funnel = this.renderFunnel()

        return  (
            <Fragment>
                <TextContact
                    body={entry.description}
                    afterBody={funnel}
                    contactWidgets={contactWidgets}
                />
                {children}

                { entry.breaker && (
                    <Breaker
                    {...entry.breaker.entry}
                    titleTag={'h2'}
                    white={true} />
                )}
            </Fragment>
        )
    }

    renderHeadline() {
        const { entry } = this.props

        return (
            <HeroSmall headline={entry.title} backgroundImage={entry.heroBackgroundImage} />
        )
    }

    renderFunnel() {
        const { entry } = this.props
        return (
            <JobFunnel job={entry} />
        )
    }

    render() {
        return (
            <Base>
                {this.renderHeadline()}
                {this.renderContent()}
            </Base>
        )
    }

}
