/** @jsx jsx */

import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";
import {jsx} from "@emotion/core";
import {Wrap} from "../../components/grid/Container";

const ElementThird = styled.div`
	${tw`px-6 lg:my-0 lg:w-4/12`};
`

export const Base = styled.div`
	${tw`flex flex-wrap w-full`};
`

export const ColumnThird = ({ children }) => (
    <ElementThird>
        {children}
    </ElementThird>
)

export const ColumnThirdContainer = ({ children, reverseOrder }) => (
    <Wrap>
        <Base>
            {children}
        </Base>
    </Wrap>
)