/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {getStyles} from "../utils";

const Element = styled.a`
  ${tw`bg-brand text-white font-display py-3 px-10 rounded`};
`

export const ElementButton = styled.button`
  ${tw`bg-brand text-white font-display py-3 px-10 rounded`};
`

const bordered = css(tw`bg-transparent border-2 border-brand text-brand`)
const inverted = css(tw`bg-white antialiased text-green`)

const small = css(tw`px-4 py-1`)
const styleables = { inverted, small, bordered }

export const ButtonLink = (props) => {
    const {title, href, isButton, onClick} = props
    const styles = getStyles(props, styleables)

    return (
        isButton ? (
            <ElementButton css={styles} onClick={onClick}>
                {title}
            </ElementButton>
        ) : (
            <Element css={styles} href={href}>
                {title}
            </Element>
        )

    )
}

export default ButtonLink
