/** @jsx jsx */

import {Component} from 'react'
import {jsx} from '@emotion/core'

import Sizes from 'react-sizes';
import ButtonLink from "./ButtonLink";
import tw from "tailwind.macro"

class NavbarJobs extends Component {

    render() {
        return (
            <div tw="mr-8">
                <ButtonLink small bordered title={'Jetzt bewerben!'} href={'/jobs'} />
            </div>
        )
    }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default Sizes(mapSizesToProps)(NavbarJobs);
