import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../Title";
import Container from "../../components/grid/Container";
import {Column57Container, ColumnTight, ColumnWide} from "../../components/grid/Column57";
import Text from "../../components/Text";

const Image = styled.img`
    ${tw`w-full`};
`

class TextImage extends Component {

    static defaultProps = {
        titleTag: 'h2',
        inlineHeadline: false,
        reverseOrder: false,
    }

    state = {
        menuOpen: false
    }

    render() {
        const {image, headline, titleTag, body, inlineHeadline, rowReverse} = this.props

        return (
            <div className="textimage">
                { !inlineHeadline ? (
                    <Container padded={false} paddedTop={true}>
                        <Title titleTag={titleTag} title={headline} mobileCenter={true} bordered={true} />
                    </Container>
                ) : null}
                <Container padded={inlineHeadline} paddedBottom={!inlineHeadline}>
                    <Column57Container reverseOrder={rowReverse}>
                        <ColumnTight>
                            { inlineHeadline ? (
                                <Title titleTag={titleTag} title={headline} mobileCenter={true} borderedY={true} />
                            ) : null}
                            <Text text={body} />
                        </ColumnTight>
                        <ColumnWide>
                            {image ? (
                                <Image src={image} alt=""/>
                            ) : null}
                        </ColumnWide>
                    </Column57Container>
                </Container>
            </div>
        )
    }
}

export default TextImage
