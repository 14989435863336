/** @jsx jsx */

import {Component} from 'react'

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../../components/Title";
import Text from "../../components/Text";
import {Wrap} from "../../components/grid/Container";
import {SingleCenter} from "../../components/grid/SingleCenter";
import Link from "../../components/Link";
import {paddingStyle} from "../../components/grid/Container";

const Base = styled.div`
  ${tw`py-10`};
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-color: #FEFCF2;
  width: 100%;
  text-align: center;
`;

class Breaker extends Component {

    static defaultProps = {
        titleTag: 'h2',
        white: false
    }

    state = {
        menuOpen: false
    }

    render() {
        const {backgroundImage, headline, titleTag, body, cta, ctaLink, white} = this.props

        return (
            <Base image={backgroundImage} className="breaker">
                <Wrap css={paddingStyle}>
                    <SingleCenter padded={false}>
                        <Title titleTag={titleTag} title={headline} white={true} center bordered />
                    </SingleCenter>
                    <SingleCenter wide padded={false}>
                        <Text white={true} text={body} />
                        { cta ? (
                            <Link href={ctaLink} inverted={white} title={cta} />
                        ) : null}
                    </SingleCenter>
                </Wrap>
            </Base>
        )
    }
}

export default Breaker
