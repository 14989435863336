/** @jsx jsx */

import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import {jsx} from "@emotion/core";
import tw from "tailwind.macro"
const nl2br = require('react-nl2br');

const Title = styled.h1`
    ${tw`text-lg  md:text-2xl lg:text-4xl xl:text-5xl`};
`

const Wrap = styled.div`
    ${tw`w-full flex flex-col py-10 min-h-0 lg:min-h-3/4 justify-center`};
    background-size: cover;
`

const WrapLink = styled.a`
    ${tw`w-full flex flex-col py-10 min-h-0 lg:min-h-3/4 justify-center`};
    background-size: cover;
`

const Content = styled.div`
    ${tw`w-full px-8 text-center md:text-left py-10 mx-auto`};
    max-width: 1280px;
`

const HeroSubline = styled.div`
    ${tw`md:pl-4 pt-8 md:pt-4 text-white w-1/2 border-t-8 pb-10 text-center md:text-left self-center md:self-end`};
`

class Hero extends Component {
    state = {
        menuOpen: false
    }

    render() {
        const {backgroundImage, headline, subline, link} = this.props
        if (link) {
            return (
                <WrapLink href={link} css={{backgroundImage: `url(${backgroundImage})`}}>
                    <Content>
                        <Title>{nl2br(headline)}</Title>
                    </Content>
                    <HeroSubline>{subline} ></HeroSubline>
                </WrapLink>
            )
        }
        return (
            <Wrap css={{backgroundImage: `url(${backgroundImage})`}}>
                <Content>
                    <Title>{nl2br(headline)}</Title>
                </Content>
                <HeroSubline>{subline}</HeroSubline>
            </Wrap>
        )
    }
}
export default Hero
