export default class ContentMapper {

    static makeTeaser(body, suffix) {
        if (!suffix) {
            suffix = '...'
        }
        return `${body.split(' ').slice(0, 45).join(' ')} ${suffix}`
    }

    static formatEntryDate(date) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        return (new Date(date)).toLocaleDateString('de-DE',options)
    }
}