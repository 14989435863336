/** @jsx jsx */

import { css, jsx } from '@emotion/core'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

export const Wrap = styled.div`
	${tw`mx-auto w-full`};
	max-width: 1280px;
`

export const paddingStyle = css(tw`my-10 px-4`)
export const paddingBottomStyle = css(tw`mb-10 px-4`)
export const paddingTopStyle = css(tw`mt-10 px-4`)
export const paddingXStyle = css(tw`px-4`)

export const Base = styled.div`
	${tw`flex mx-6`};
`

export const GridNormalizer = styled.div`
	${tw`-mx-6`};
`

const Container = ({ children, padded, paddedBottom, paddedTop, paddedX }) => (
    <Wrap css={padded !== false ? paddingStyle : paddedBottom === true ? paddingBottomStyle : paddedTop === true ? paddingTopStyle : paddedX === true ? paddingXStyle : {}}>
        <GridNormalizer>
            <Base>
                {children}
            </Base>
        </GridNormalizer>
    </Wrap>
)

export default Container;
