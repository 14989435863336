/** @jsx jsx */

import {Component} from 'react'

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
const nl2br = require('react-nl2br');

//import Title from "../Title";

const Title = styled.h1`
    ${tw`text-xl leading-tight text-center md:text-left md:text-4xl lg:text-3xl`};
`

const Wrap = styled.div`
    ${tw`w-full flex flex-col py-10 px-4 min-h-0 mb-20 justify-center`};
    background-size: cover;
`

const Content = styled.div`
    ${tw`text-left md:text-left py-10 mx-auto w-full`};
    max-width: 1280px;
`


class HeroSmall extends Component {
    state = {
        menuOpen: false
    }

    static defaultProps = {
        backgroundImage: '/img/hero1.jpg'
    }

    render() {
        const {headline, backgroundImage} = this.props
        return (
            <Wrap css={{backgroundImage: 'url('+backgroundImage+')'}}>
                <Content>
                    <Title>
                        {nl2br(headline)}
                    </Title>
                </Content>
            </Wrap>
        )
    }
}
export default HeroSmall
