/** @jsx jsx */

import {Component} from 'react'
import {jsx} from '@emotion/core'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Sizes from 'react-sizes';

import MenuToggle from "./MenuToggle";
import Container from "./grid/Container";
import NavbarJobs from "./NavbarJobs";

const LogoDesktop = styled.img`
	height: 65px;
`

const LogoMobile = styled.img`
	height: 45px;
`

const PhoneNumber = styled.a`
	${tw`font-display pr-10 text-brand flex`};
	font-variant: oldstyle-nums;
`
const PhoneIcon = styled.img`
    ${tw`mr-2`};
`

const Base = styled.div`
    ${tw`flex w-full pt-4 pb-4 justify-between`};
`

const Actions = styled.div`
    ${tw`flex flex-row items-center`};
`

class Navbar extends Component {

    render() {
        const {isMenuOpen, onMenuToggle, isMobile, showJobs} = this.props

        return (
            <Container padded={false} paddedX={true}>
                <Base>
                    <a href="/">
                        { !isMobile ? (
                            <LogoDesktop src="/img/logo.svg" alt=""/>
                        ) : (
                            <LogoMobile src="/img/logo-signet.svg" alt=""/>
                        )}
                    </a>
                    <Actions>
                        { !isMobile && (
                            <PhoneNumber href={'tel:04231982720'}>
                                <PhoneIcon src="/img/phone.svg" alt=""/>
                                    <span>04231 98 272 - 0</span>
                            </PhoneNumber>
                        )}
                        {
                            (showJobs || true) && (
                                <NavbarJobs />
                            )
                        }
                        <MenuToggle isOpen={isMenuOpen} onMenuToggle={onMenuToggle}/>
                    </Actions>
                </Base>
            </Container>
        )
    }
}


const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default Sizes(mapSizesToProps)(Navbar);
