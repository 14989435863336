/** @jsx jsx */

import React, {Component} from 'react'

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../../components/Title";
import Text from "../../components/Text";
import ContentHelper from "../../helpers/ContentHelper";
import Container, {Wrap} from "../../components/grid/Container";
import {Column57Container, ColumnWider, ColumnTighter, ColumnWide, ColumnTight} from "../../components/grid/Column57";
import Link from "../../components/Link";
import Pagination from "../../components/Pagination";

const Base = styled.div`
  ${tw`-mt-8`};
  width: 100%;
`;

const ListWrap = styled.div`
  ${tw`flex flex-col`}
`

class SteuertipChannel extends Component {

    static defaultProps = {
        titleTag: 'h2',
    }

    state = {
        menuOpen: false
    }

    render() {
        const {entries, pagination} = this.props
        return (
            <Base className="steuertip-channel">
                <Container padded={false} paddedBottom={true}>
                    <ListWrap>
                        {
                            entries.map((entry, index) => (
                                <SteuertipChannelTeaser key={index} entry={entry} />
                            ))
                        }
                    </ListWrap>
                </Container>
                <Pagination pagination={pagination} />
                <br/> <br/>
            </Base>
        )
    }
}

const TeaserBase = styled.article`
  ${tw`flex flex-col md:flex-row mb-20`};
  width: 100%;
`;



export class SteuertipChannelTeaser extends Component {
    render() {
        const {title, body, date, url} = this.props.entry
        const titleTag = 'h2'


        const postDate = ContentHelper.formatEntryDate(date)
        const bodyTeaser = ContentHelper.makeTeaser(body)

        return (
            <Column57Container>
                <TeaserBase>
                    <div tw="md:px-6 lg:my-0 lg:w-5/12">
                        <Title titleTag={titleTag} title={title} bordered reducedSize />
                        <div css={{marginTop : -20}}>
                            <Text muted small marginless>
                                {postDate}
                            </Text>
                        </div>
                    </div>
                    <div tw="lg:w-7/12 lg:px-6">
                        <Text left text={bodyTeaser} />
                        <Link href={url} title={'Steuertipp weiterlesen'} />
                    </div>
                </TeaserBase>
            </Column57Container>
        )
    }
}

export default SteuertipChannel
