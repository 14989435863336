/** @jsx jsx */

import {Fragment} from 'react'

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";
import {getStyles} from "../utils";

const Headline1 = styled.h1`
    hyphens: auto;
`
const Headline2 = styled.h2`
    hyphens: auto;
`
const Headline3 = styled.h3`
    hyphens: auto;
`
const Headline4 = styled.h4`
    hyphens: auto;
`

const Content = styled.span`
    ${tw`pb-6 block`}
`

const white = css(tw`text-white`)
const center = css(tw`text-center block`)
const bordered = css(tw`pb-4 border-b-8 mb-8`)
const borderedMedium = css(tw`pb-2 border-b-4 mb-4`)
const borderedY = css(tw`py-4 border-b-8 border-t-8 mb-8`)
const mobileCenter = css(tw`text-center lg:text-left`)
const reducedSize = css(tw`text-lg lg:text-xl`)
const medium = css(tw`text-md lg:text-md`)
const inline = css(tw`mb-0 pb-0`)

const styleables = {
    white,
    center,
    mobileCenter,
    bordered,
    borderedY,
    borderedMedium,
    reducedSize,
    medium,
    inline
}

export const Title = (props) => {

    const { title, titleTag, classname } = props
    const styles = getStyles(props, styleables)

    if (titleTag === 'h1') {
        return (
            <Headline1>
                <Content classname={classname} css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}

                </Content>
            </Headline1>
        )
    }
    if (titleTag === 'h2') {
        return (
            <Headline2>
                <Content classname={classname} css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline2>
        )
    }
    if (titleTag === 'h3') {
        return (
            <Headline3>
                <Content classname={classname} css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline3>
        )
    }
    if (titleTag === 'h4') {
        return (
            <Headline4>
                <Content classname={classname} css={styles}>
                    {title ? title.split('\n').map((item, key) => {
                                return <Fragment key={key}>{item}<br/></Fragment>
                            }) : null}
                </Content>
            </Headline4>
        )
    }
    return (<div dangerouslySetInnerHTML={{ __html: title }} />)
}

export default Title
