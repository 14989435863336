/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {SingleCenter} from "./grid/SingleCenter";

const Footer = styled.nav`
    ${tw`bg-brand-dark py-4 text-center mx-auto text-white`};
`

const MenuLink = styled.a`
    ${tw`text-white inline-block mx-4`};
`

export const MenuFooter = ({menuItems}) => {
    return (
        <Footer className={'footer-menu'}>
            <SingleCenter wide={true} padded={false}>
                {
                    menuItems && (
                        menuItems.map((item, index) => (
                        <MenuLink key={index} title={item.name} href={item.url}>{item.name}</MenuLink>
                    )))
                }
            </SingleCenter>
        </Footer>
    )
}

export default MenuFooter
