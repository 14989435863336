/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import styled from "@emotion/styled/macro";
import tw from "tailwind.macro";

const Wrap = styled.div`
	${tw`flex lg:-mx-6  lg:w-full`};
	max-width: 1280px;
	justify-content: space-between;
`

const orderRegular = css(tw`flex-col lg:flex-row`)
const orderReverse = css(tw`flex-col lg:flex-row-reverse`)

const ElementWide = styled.div`
	${tw`lg:w-7/12 lg:px-6`};
`

const ElementWider = styled.div`
	${tw`lg:w-8/12 lg:px-6`};
`

const ElementTight = styled.div`
	${tw`px-6 w-full lg:my-0 lg:w-5/12`};
`

const ElementTighter = styled.div`
	${tw`px-6 w-full  lg:my-0 lg:w-4/12`};
`

export const ColumnWide = ({ children }) => (
    <ElementWide>
        {children}
    </ElementWide>
)

export const ColumnWider = ({ children }) => (
    <ElementWider>
        {children}
    </ElementWider>
)

export const ColumnTight = ({ children }) => (
    <ElementTight>
        {children}
    </ElementTight>
)

export const ColumnTighter = ({ children }) => (
    <ElementTighter>
        {children}
    </ElementTighter>
)
export const Column57Container = ({ children, reverseOrder }) => (
    <Wrap css={!reverseOrder ? orderRegular : orderReverse }>
        {children}
    </Wrap>
)
