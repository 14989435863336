import React from 'react'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Container from "./grid/Container";
import SVG from 'react-inlinesvg';

const Wrap = styled.nav`
	${tw`font-display bg-black-overlay w-screen h-full text-white fixed pt-10 pb-40 overflow-y-scroll`};
`

const SVGWrap = styled.span`
    ${tw`lg:pl-4`}

	display: inline-block;
`

export default class Menu extends React.Component {
    render() {
        const {isMenuOpen, menuItems} = this.props
        if (!isMenuOpen) return null

        return (
            <Wrap className={'menu'}>
                <Container padded={false}>
                    <MenuItemList>
                        {menuItems.map((item, index) => (
                            <MenuItem key={index} title={item.name} href={item.url} />
                        ))}
                    </MenuItemList>
                </Container>
            </Wrap>
        )
    }
}

const MenuItemList = styled.div`
    ${tw`flex flex-col w-full`};
`
const MenuItemElement = styled.a`
    ${tw`font-display text-white flex flex-row font-display text-lg justify-between lg:items-center lg:justify-start lg:text-2xl pl-4 pr-4 pb-4`};
  

`

const MenuItem = ({title, href}) => (
    <MenuItemElement href={href}><span>{ title }</span> <SVGWrap><SVG src={'img/chevron-right.svg'} /></SVGWrap></MenuItemElement>
)
