/** @jsx jsx */

import React, {Component} from 'react'

import {jsx} from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../../components/Title";
import Container from "../../components/grid/Container";
import {Column57Container} from "../../components/grid/Column57";
import Link from "../../components/Link";
import ButtonLink from "../ButtonLink";

const Base = styled.div`
  ${tw`-mt-8`};
  width: 100%;
`;

const ListWrap = styled.div`
  ${tw`flex flex-col border-t-2 border-b-2 border-grey-bright py-4`}
`

class JobChannel extends Component {

    static defaultProps = {
        titleTag: 'h2',
    }

    state = {
        menuOpen: false
    }

    render() {
        const title = 'Aktuelle Stellenangebote'
        const titleTag = 'h2'

        const {entries} = this.props
        return (
            <Base className="job-channel">
                <Container padded={false} paddedBottom={true}>
                    <div tw="mt-8 w-full lg:w-2/3">
                        <Title  titleTag={titleTag} title={title} border />
                        <ListWrap>
                            {
                                entries.map((entry, index) => (
                                    <JobChannelTeaser key={index} entry={entry} />
                                ))
                            }
                        </ListWrap>
                    </div>
                </Container>
            </Base>
        )
    }
}

const TeaserBase = styled.article`
  ${tw`flex flex-col md:flex-row my-4`};
  width: 100%;
`;

export class JobChannelTeaser extends Component {
    render() {
        const {title, url} = this.props.entry
        const titleTag = 'h2'

        return (
            <Column57Container>
                <TeaserBase>
                    <div tw="md:px-6 lg:my-0 lg:w-8/12">
                        <a href={url}>
                            <Title inline titleTag={titleTag} title={title} medium />
                        </a>
                    </div>
                    <div tw="lg:w-4/12 lg:px-6 flex lg:justify-end h-auto">
                        <div tw="mt-4 lg:mt-0 flex flex-col content-center">
                            <ButtonLink href={url} title={'Details anzeigen'} small bordered />
                        </div>
                    </div>
                </TeaserBase>
            </Column57Container>
        )
    }
}

export default JobChannel
