import "./styles/index.css";
import {render} from "react-dom";

import React, {Component} from "react";
import styled from "@emotion/styled/macro"
import tw from 'tailwind.macro'
import Navbar from "./components/Navbar";
import PageFooter from "./components/PageFooter";
import Menu from "./components/Menu";
import ContentMapper from "./helpers/ContentMapper";
import MenuFooter from "./components/MenuFooter";

const Header = styled('header')`
  ${tw`sticky top-0 left-0 w-screen bg-white-alpha sticky`};
`;

const ErrorMessage = styled.div`
    ${tw`w-full text-center font-display py-40 text-brand`};
`

class App extends Component {

    state = {
        isMenuOpen: false,
        data: {}
    }

    _toggleMenu = (isMenuOpen) => {
        this.setState({isMenuOpen})
    }

    componentWillMount() {
        const data = JSON.parse(document.getElementById('data').innerHTML);
        this.setState({data})
    }

    renderContent() {
        const { entry, error } = this.state.data
        if (!entry) {
            if (error) {
                return this.renderError()
            } else {
                return null
            }
        } // todo: 404?
        return ContentMapper.mapSection(entry)
    }

    renderError() {
        const { error } = this.state.data

        return (
            <ErrorMessage>{ error.message }</ErrorMessage>
        );
    }

    render() {
        const { menu, generals } = this.state.data

        return (
            <div className="app">
                <Header>
                    <Navbar showJobs={generals.jobCount} isMenuOpen={this.state.isMenuOpen} onMenuToggle={this._toggleMenu}/>
                </Header>
                <Menu isMenuOpen={this.state.isMenuOpen} menuItems={menu.mainMenu} />
                { this.renderContent() }
                <PageFooter/>
                <MenuFooter menuItems={menu.footerMenu} />
            </div>
        );
    }
}

render(<App/>, document.getElementById('root'));
