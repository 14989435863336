import React from "react";
import Hero from "../components/blocks/Hero";
import TextContact from "../components/blocks/TextContact";
import Breaker from "../components/blocks/Breaker";
import TextImage from "../components/blocks/TextImage";
import LatestSteuertip from "../components/blocks/LatestSteuertip";
import Team from "../components/blocks/Team";
import Accordeon from "../components/blocks/Accordeon";
import Default from "../components/sections/Default";
import Page from "../components/sections/Page";
import TextBlock from "../components/blocks/TextBlock";
import Steuertip from "../components/sections/Steuertip";
import SteuertipChannel from "../components/blocks/SteuertipChannel";
import JobChannel from "../components/blocks/JobChannel";
import Job from "../components/sections/Job";

class ContentMapper {

    static mapSection(entry) {
        console.log(entry.type)

        switch (entry.type) {
            case 'steuertip':
                return <Steuertip entry={entry} />
            case 'page':
                return <Page entry={entry} />
            case 'job':
                return <Job entry={entry} />
            case 'homepage':
            default:
                return <Default entry={entry} />
        }
    }

    static mapContentBlocks(contentBlocks) {
        console.log(contentBlocks)

        return contentBlocks.map((block, i) => {
            switch (block.type) {
                case 'hero':
                    if (!block.entries) return
                    const heroEntry = block.entries[Math.floor(Math.random() * block.entries.length)]
                    return (
                        <Hero
                            key={i}
                            {...heroEntry} />
                    )
                case 'textContact':
                    return (
                        <TextContact
                            key={i}
                            rowReverse={block.layout.includes('rowReverse')}
                            {...block} />
                    )
                case 'breaker':
                    if (!block.entries) return

                    const entry = block.entries[Math.floor(Math.random() * block.entries.length)]
                    const white = entry.layout ? Object.values(entry.layout).findIndex(e => e.selected && e.white ) : null
                    return (
                        <Breaker
                            key={i}
                            {...entry}
                            titleTag={'h2'}
                            white={white}/>
                    )
                case 'text':
                    return (
                        <TextBlock
                            key={i}
                            {...block}
                        />
                    )
                case 'textImage':
                    return (
                        <TextImage
                            key={i}
                            {...block}
                            rowReverse={block.layout.includes('rowReverse')}
                            inlineHeadline={block.layout.includes('inlineHeadline')}
                        />
                    )
                case 'blocks':
                    switch (block.blockType) {
                        case 'steuertipChannel':
                            return (
                                <SteuertipChannel key={i} entries={block.entries} pagination={block.pagination}/>
                            )
                        case 'jobChannel':
                            return (
                                <JobChannel key={i} entries={block.entries} pagination={block.pagination}/>
                            )
                        case 'latestSteuertip':
                            return (
                                <LatestSteuertip key={i} entry={block.entry}/>
                            )
                        default: break;
                    }
                    return (
                        <TextImage
                            key={i}
                            {...block}
                            reverseOrder={true}/>
                    )
                case 'team':
                    return (
                        <Team key={i} {...block} />
                    )
                case 'accordeon':
                    return (
                        <Accordeon key={i} {...block} />
                    )
                default:
                    console.info(`missing blocktype: ${block.type}`)
                    break;
            }
            return null
        }).filter(b => b)
    }
}

export default ContentMapper
