/** @jsx jsx */

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import {SingleCenter} from "./grid/SingleCenter";
import SVG from "react-inlinesvg";

const Footer = styled.footer`
    ${tw`bg-brand py-1 text-center mx-auto text-white`};
`
const Logo = styled.img`
    ${tw`mx-auto pb-10`};
`
const Award = styled.a`
    img {
        width: 120px;
        ${tw`mx-2 pt-8`};
    }
`

const Awards = styled.div`
    ${tw`flex justify-center`};
`

const Socials = styled.div`
    ${tw`mt-4 flex flex-col`};
`

const SocialLinks = styled.div`
    ${tw`flex flex-row justify-center`};
`

const SocialLink = styled.a`
    ${tw`flex flex-row mx-4`};
`


//const white = css(tw`text-white`)

export const PageFooter = (props) => {
    return (
        <Footer className={'page--footer'}>
            <SingleCenter wide={true}>
                <Logo src="/img/logo-inverted.svg" alt=""/>
                <span>Schladebusch & Bartling</span>
                <div>
                    Große Straße 2<br/>
                    27283 Verden<br/>
                    <br/>
                    Fon<br/>
                    04231 98 272 - 0<br/>
                    <br/>
                    Fax<br/>
                    04231 98 272 - 22<br/>
                    <br/>
                    <strong>Email</strong><br/>
                    info@steuern-verden.de
                </div>
                <Socials>
                    <span tw="mb-4 block">Social Media</span>
                    <SocialLinks>
                        <SocialLink href="https://www.facebook.com/profile.php?id=100057581433718" target={"_blank"} title={'Folge uns bei Facebook'}>
                            <img src={'img/facebook.svg'}  width={48}  title={'Logo von Facebook'} alt={'Logo von Facebook'} />
                        </SocialLink>
                        <SocialLink href="https://www.instagram.com/schladebuschbartling" target={"_blank"} title={'Folge uns bei Instagram'}>
                            <img src={'img/instagram.svg'}  width={48} title={'Logo von Instagram'} alt={'Logo von Instagram'} />
                        </SocialLink>
                    </SocialLinks>
                </Socials>
                <Awards>
                    <Award href="https://stbv.tax/" target={"_blank"}>
                        <img src="/img/siegel-ea-2022.png"  alt="Exzellenter Arbeitgeber - Steuerberater Verband"/>
                    </Award>
                    <Award href="https://stbv.tax/" target={"_blank"}>
                        <img src="/img/siegel-exzellenz.svg"  alt="Exzellenter Arbeitgeber 2022 - Steuerberater Verband"/>
                    </Award>
                </Awards>
            </SingleCenter>
        </Footer>
    )
}

export default PageFooter
