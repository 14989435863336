import React, {Component} from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Container from "../../components/grid/Container";
import Text from "../../components/Text";
import {ColumnThird, ColumnThirdContainer} from "../../components/grid/ColumnThird";
import Title from "../../components/Title";
import TextBlock from "./TextBlock";
const nl2br = require('react-nl2br');

const Wrap = styled.div`
    ${tw`w-full pb-10`};
`

const Image = styled.img`
    ${tw`w-full mb-2`};
`

class Team extends Component {

    static defaultProps = {
        titleTag: 'h2',
        inlineHeadline: false,
        reverseOrder: false,
    }

    state = {
        menuOpen: false
    }

    render() {
        const {headline, body, teamMembers} = this.props

        return (
            <Wrap className="textimage">
                <Container>
                    <TextBlock headline={headline} body={body}/>
                </Container>
                <ColumnThirdContainer>
                    {
                        teamMembers.map((member,i) => (
                            <ColumnThird key={i}>
                                {member.image ? (
                                    <Image src={member.image} alt=""/>
                                ) : null}
                                <Title titleTag={'h3'} title={member.headline} borderedMedium/>
                                <Text small>{nl2br(member.job)}</Text>

                            </ColumnThird>
                        ))
                    }
                </ColumnThirdContainer>
            </Wrap>
        )
    }
}

export default Team
