/** @jsx jsx */

import {Component} from 'react'

import { jsx } from '@emotion/core'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Title from "../../components/Title";
import Text from "../../components/Text";
import {paddingBottomStyle, Wrap} from "../../components/grid/Container";
import {SingleCenter} from "../../components/grid/SingleCenter";
import Button from "../../components/Button";
import {paddingStyle} from "../../components/grid/Container";
import ButtonLink from "../../components/ButtonLink";

const Base = styled.div`
  ${tw`py-10`};
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-color: #FEFCF2;
  width: 100%;
  text-align: center;
`;

class LatestSteuertip extends Component {

    static defaultProps = {
        titleTag: 'h2',
    }

    state = {
        menuOpen: false
    }

    render() {
        const {image, entry, white, url} = this.props
        const {title, body} = entry
        const titleTag = 'h2'

        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const postDate = (new Date(entry.date)).toLocaleDateString('de-DE',options)
        const bodyTeaser = `${body.split(' ').slice(0, 50).join(' ')} ...`

        return (
            <Base image={image} className="steuertip-teaser">
                <Wrap css={paddingBottomStyle}>
                    <div tw="w-full md:w-2/3 mx-auto">
                        <Title titleTag={titleTag} title={title} white={white} center bordered  reducedSize />
                        <Text muted small marginless>
                            Steuertip vom {postDate}<br />
                            <br/>
                        </Text>
                    </div>
                    <SingleCenter wide padded={false}>
                        <Text mobileCenter text={bodyTeaser} />
                        <ButtonLink href={entry.url} title={'Den kompletten Steuertipp lesen'} />
                    </SingleCenter>
                </Wrap>
            </Base>
        )
    }
}

export default LatestSteuertip
