import React from 'react'
import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"

var classNames = require('classnames');

const Button = styled.button`
	${tw`font-display focus:outline-none text-brand`};
`
const ButtonContent = styled.div`
    ${tw`flex`};
`
const ButtonText = styled.span`
    ${tw`px-4 hidden lg:block`};
`

class MenuToggle extends React.Component {

    _handleClick = (click) => {
        click.nativeEvent.preventDefault();

        const newState = !this.props.isOpen
        if (newState) {
            document.body.classList.add('is-menu-open')
        } else {
            document.body.classList.remove('is-menu-open')
        }


        if (this.props.onMenuToggle) this.props.onMenuToggle(newState)
    };

    render() {
        const {isOpen} = this.props
        return (
            <Button className={classNames('hamburger', 'hamburger--collapse', isOpen ? 'is-active' : '')}
                    onClick={this._handleClick}>
                <ButtonContent>
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                    <ButtonText>Menü</ButtonText>
                </ButtonContent>
            </Button>
        )
    }
}


export default styled(MenuToggle)`
  display: none;
`;
