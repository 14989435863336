import React from 'react'

import styled from "@emotion/styled/macro"
import tw from "tailwind.macro"
import Container from "./Container";

const Element = styled.div`
	${tw`mx-auto text-center`};
`

const ElementWide = styled.div`
	${tw`md:w-2/3 mx-auto text-center`};
`

export const SingleCenter = ({ children, wide, padded }) => (
    <Container padded={padded}>
		{!wide ? (
			<Element>
				{children}
			</Element>
		) : (
			<ElementWide>
				{children}
			</ElementWide>
		)}
    </Container>
)
