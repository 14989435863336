/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import React, { Fragment } from 'react'
import tw from 'tailwind.macro';
import { getStyles } from '../utils';


const centered = css(tw`block flex justify-center items-center py-8`)

const styleables = { centered }

export const Loader = (props) => {
	const styles = getStyles(props, styleables)

	return (
		<div className={'loader-wrap'} css={styles}>
			<div className="loader">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}
